
import { defineComponent, ref, Ref, onMounted, onBeforeUnmount } from 'vue'
import MediumEditor from 'medium-editor'

export default defineComponent({
  name: 'va-medium-editor',
  props: {
    editorOptions: {
      type: Object,
      default: () => ({
        buttonLabels: 'fontawesome',
        autoLink: true,
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'anchor',
            'h1',
            'h2',
            'h3',
          ],
        }
      })
    }
  },
  setup(props, { emit }) {
    const editorElement: Ref<null | HTMLElement> = ref(null)
    let editor: typeof MediumEditor | null = null

    onMounted(() => {
      if (!editorElement.value) { return; }

      editor = new MediumEditor(editorElement.value, props.editorOptions)
      emit('initialized', editor)
    })

    onBeforeUnmount(() => {
      if (editor) {
        editor.destroy()
      }
    })

    return {
      editorElement
    }
  },
})
